import AnalyticsIcon from "@mui/icons-material/Analytics";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import ForumTwoToneIcon from "@mui/icons-material/ForumTwoTone";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import SettingsIcon from "@mui/icons-material/Settings";
import SportsKabaddiTwoToneIcon from "@mui/icons-material/SportsKabaddiTwoTone";
import StorefrontsIcon from "@mui/icons-material/Storefront";
import { styled } from "@mui/material";

export function featureIcons() {
  const EdgeIcon = styled(SportsKabaddiTwoToneIcon)(
    ({ theme }) => `
        color: ${theme.colors.features.edge};
`,
  );

  const DealIcon = styled(ForumTwoToneIcon)(
    ({ theme }) => `
        color: ${theme.colors.features.deal};
`,
  );

  const ViewIcon = styled(AnalyticsIcon)(
    ({ theme }) => `
        color: ${theme.colors.features.view};
`,
  );

  const InsightIcon = styled(LibraryBooksIcon)(
    ({ theme }) => `
        color: ${theme.colors.features.insights};
`,
  );

  const StorefrontIcon = styled(StorefrontsIcon)(
    ({ theme }) => `
        color: ${theme.colors.features.insights};
`,
  );
  const StyledSettingsIcon = styled(SettingsIcon)(
    ({ theme }) => `
        color: ${theme.colors.features.insights};
`,
  );
  const RangeReviewIcon = styled(AnalyticsOutlinedIcon)(
    ({ theme }) => `
        color: ${theme.colors.features.insights};
`,
  );
  return {
    ViewIcon,
    EdgeIcon,
    DealIcon,
    InsightIcon,
    StorefrontIcon,
    StyledSettingsIcon,
    RangeReviewIcon,
  };
}
