export enum Module {
  AANICopilot = "AANI Copilot",
  DEAL = "Deal",
  RANGE_REVIEW = "Range Review",
  BUYING = "Buying",
  GENERAL_TRAINING = "General Training",
  NEGOTIATION_TRAINING = "Negotiation Training",
  MIA = "MIA",
  INSIGHT = "Insight",
}
