export interface Context {
  segmentation: string;
  value: string;
  power: string;
  planningTools: string;
  prepare: string;
  prime: string;
  engage: string;
  propose: string;
  manage: string;
  followUpActions: string;
  negotiationType: string;
}

export interface Path {
  potentialFirstMove: string;
  potentialFirstProposal: string;
  potentialObjections: string;
  ourResponse: string;
  expectedCounterProposal: string;
  counterResponse: string;
  conclusion: string;
}

export interface Execution {
  path1: Path;
  path2: Path;
  pathRecommendation: string;
}

export interface Overview {
  summary: string;
  reason: string;
  missingInfo: string;
  assumptions: string;
  delta: string;
  risks: string;
  levers: string[];
  players: string[];
  benefits: string[];
  potentialObjections: string[];
  objectionResponses: string[];
  tasks: string[];
  checkWithLegal: string[];
}

export interface NegotiationOutput {
  overview: Overview;
  context: Context;
  execution: Execution;
}

export interface Negotiation {
  description: string;
  theirObjectives: string;
  ourObjectives: string;
  negotiatingWith: string;
  barriers: string;
  id: string;
  title: string;
  status: NegotiationStatus;
  state: NegotiationState;
  currentRunId: string;
  latestRecommendation: string;
  pendingQuestion: string;
  currentStage: string;
  completionDate: Date;
  setupProgressPerc: number;
  latestOutput: NegotiationOutput;
}

export enum NegotiationState {
  SETUP = "SETUP",
  CHAT = "CHAT",
}

export enum NegotiationStatus {
  INITIALISING = "INITIALISING",
  THINKING = "THINKING",
  WAITING_FOR_USER_INPUT = "WAITING_FOR_USER_INPUT",
  COMPLETED = "COMPLETED",
  ERROR = "ERROR",
  DRAFT = "DRAFT",
}

export interface NegotiationSummary {
  id: string;
  title: string;
  summary: string;
  status: NegotiationDisplayStatus;
  endDate: string;
}

export enum NegotiationDisplayStatus {
  ERROR = "ERROR",
  RUNNING = "RUNNING",
  IDLE = "IDLE",
  REQUIRES_ACTION = "REQUIRES_ACTION",
  INITIALISING = "INITIALISING",
  COMPLETED = "COMPLETED",
  DRAFT = "DRAFT",
}
