import { Action, combineReducers } from "@reduxjs/toolkit";
import edge from "src/slices/aani/edge";
import negotiation from "src/slices/aani/negotiation";
import negotiations from "src/slices/aani/negotiations";
import rangeReview from "src/slices/aani/rangereview";
import suppliers from "src/slices/aani/supplier";
import workspace from "src/slices/aani/workspace";
import deal from "src/slices/deal";
import insight from "src/slices/insight/insight";
import mia from "src/slices/mia/mia";
import rangeReviewAssistance from "src/slices/range-review/assistance";
import rangeReviewTerms from "src/slices/range-review/terms";
import userSettings from "src/slices/settings/user";
import training from "src/slices/training";
import trainingNegotiation from "src/slices/training/trainingNegotiation";

const appReducer = combineReducers({
  deal: deal.reducer,
  edge: edge.reducer,
  aaniWorkspace: workspace.reducer,
  rangeReview: rangeReview.reducer,
  training: training.reducer,
  trainingNegotiation: trainingNegotiation.reducer,
  suppliers: suppliers.reducer,
  negotiations: negotiations.reducer,
  negotiation: negotiation.reducer,
  userSettings: userSettings.reducer,
  rangeReviewTerms: rangeReviewTerms.reducer,
  rangeReviewAssistance: rangeReviewAssistance.reducer,
  mia: mia.reducer,
  insight: insight.reducer,
});

type RootState = ReturnType<typeof appReducer>;

const rootReducer = (state: RootState, action: Action): RootState => {
  if (action.type === "LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
