import { useContext } from "react";

import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import { Box, IconButton, Tooltip, alpha, styled } from "@mui/material";
import { SidebarContext } from "src/contexts/SidebarContext";
import HeaderUserbox from "src/layouts/shared/Header/Userbox";
import { IHeaderMenu } from "src/models/menu";

import HeaderMenu from "./HeaderMenu";
import headerMenu from "./HeaderMenu/items";

const IconButtonPrimary = styled(IconButton)(
  ({ theme }) => `
    background: ${theme.colors.alpha.trueWhite[10]};
    color: ${theme.colors.alpha.black[70]};
    padding: 0;
    width: 42px;
    height: 42px;
    border-radius: 100%;

    &.active,
    &:active,
    &:hover {
      background: ${alpha(theme.colors.alpha.trueWhite[30], 0.2)};
      color: ${theme.colors.alpha.black[100]};
    }
`,
);

function UkoHeader({
  height,
  menuItems,
}: {
  height?: number | string;
  menuItems?: IHeaderMenu;
}) {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const headerHeight = height;

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        height={headerHeight}
        paddingX={2}
        paddingY={1}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={2}
        >
          <HeaderMenu menuItems={menuItems} />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={2}
        >
          <HeaderUserbox />
          <Box
            sx={{
              display: { lg: "none", xs: "inline-block" },
            }}
          >
            <Tooltip arrow title="Toggle Menu">
              <IconButtonPrimary color="primary" onClick={toggleSidebar}>
                {!sidebarToggle ? <MenuTwoToneIcon /> : <CloseTwoToneIcon />}
              </IconButtonPrimary>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </>
  );
}

UkoHeader.defaultProps = {
  height: "80px",
  menuItems: headerMenu,
};

export default UkoHeader;
