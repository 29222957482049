import { useEffect, useState } from "react";

import { Box, CircularProgress } from "@mui/material";
import axios, { AxiosResponse } from "axios";
import ModuleLayout from "src/layouts/shared/layout/ModuleLayout";
import { Module } from "src/models/module";

import Terms from "./Terms";

export interface TermsAcceptanceResponse {
  hasAcceptedTerms: boolean;
}

const MiaLayout = () => {
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchUserTerms = async () => {
    try {
      const termsResponse: AxiosResponse<TermsAcceptanceResponse> =
        await axios.get(
          ` ${process.env.REACT_APP_API_BASE_URL}api/accounts/current/terms/${Module.MIA}`,
        );
      setHasAcceptedTerms(termsResponse.data.hasAcceptedTerms);
    } catch (error) {
      console.error("Error fetching user terms:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserTerms();
  }, []);

  return (
    <>
      {loading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100vh"
        >
          <CircularProgress size={60} />
        </Box>
      )}
      {!loading && !hasAcceptedTerms && (
        <Terms setHasAcceptedTerms={setHasAcceptedTerms} />
      )}
      {!loading && hasAcceptedTerms && <ModuleLayout />}
    </>
  );
};

export default MiaLayout;
