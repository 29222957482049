import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { AppThunk } from "src/store";

import {
  InsightMessage,
  InsightMessageRunStatus,
  InsightSessionAction,
  InsightSessionStatus,
  KnowledgeBankDataEntry,
  KnowledgeBankDataSource,
} from "./model";

const API_PREFIX = `${process.env.REACT_APP_API_BASE_URL}api/insight`;

export interface InsightLatestSessionResponse {
  status: InsightSessionStatus;
  runId: string;
  question: string;
  contentUsed: KnowledgeBankDataEntry[];
  sources: KnowledgeBankDataSource[];
}

export interface InsightMessageResponse {
  id: string;
  msg: string;
  isRated: boolean;
  status: InsightMessageRunStatus;
}

export interface InsightState {
  runId: string;
  question: string;
  response: InsightMessage;
  actions: InsightSessionAction[];
  status: InsightMessageRunStatus;
  isProcessing: boolean;
  contentUsed: KnowledgeBankDataEntry[];
  sources: KnowledgeBankDataSource[];
}

const initialState: InsightState = {
  runId: "",
  response: null,
  question: "",
  actions: [],
  status: null,
  isProcessing: false,
  contentUsed: [],
  sources: [],
};

interface ProcessingUserInput {
  input: string;
  runId: string;
  contentUsed: KnowledgeBankDataEntry[];
  sources: KnowledgeBankDataSource[];
}

const slice = createSlice({
  name: "insight",
  initialState,
  reducers: {
    setRunId(state, action: PayloadAction<string>) {
      state.runId = action.payload;
    },
    loadResponse(state, action: PayloadAction<InsightMessage>) {
      state.response = action.payload;
      state.isProcessing = false;
      state.status = InsightMessageRunStatus.COMPLETED;
    },
    loadActions(state, action: PayloadAction<InsightSessionAction[]>) {
      state.actions = action.payload;
    },
    setStatus(state, action: PayloadAction<InsightMessageRunStatus>) {
      state.status = action.payload;
    },
    setIsProcessing(state, action: PayloadAction<boolean>) {
      state.isProcessing = action.payload;
    },
    cancelProcessing(state, action: PayloadAction<boolean>) {
      state.isProcessing = action.payload;
      state.runId = null;
      state.question = "";
      state.status = InsightMessageRunStatus.COMPLETED;
      state.response = null;
      state.actions = [];
      state.contentUsed = [];
    },
    loadedSession(state, action: PayloadAction<InsightLatestSessionResponse>) {
      state.runId = action.payload.runId;
      state.isProcessing =
        action.payload.status === InsightSessionStatus.THINKING;
      state.question = action.payload.question;
      state.contentUsed = action.payload.contentUsed;
      state.sources = action.payload.sources;
    },
    startedProcessingUserInput(
      state,
      action: PayloadAction<ProcessingUserInput>,
    ) {
      state.question = action.payload.input;
      state.contentUsed = [];
      state.sources = [];
      state.actions = [];
      state.response = null;
    },
    finishProcessingUserInput(
      state,
      action: PayloadAction<ProcessingUserInput>,
    ) {
      state.runId = action.payload.runId;
      state.isProcessing = true;
      state.contentUsed = action.payload.contentUsed;
      state.sources = action.payload.sources;
    },
  },
});

export const getLatestSession =
  (): AppThunk<Promise<void>> => async (dispatch) => {
    try {
      const response: AxiosResponse<InsightLatestSessionResponse> =
        await axios.get(`${API_PREFIX}/sessions/latest`);
      const { status, runId, question, contentUsed, sources } = response.data;
      dispatch(
        slice.actions.loadedSession({
          status,
          runId,
          question,
          contentUsed,
          sources,
        }),
      );
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const getInsightMessageResponse =
  (messageId: string): AppThunk<Promise<void>> =>
  async (dispatch) => {
    try {
      const response: AxiosResponse<InsightMessageResponse> = await axios.get(
        `${API_PREFIX}/${messageId}/response`,
      );
      const { id, msg, status, isRated } = response.data;

      if (status === InsightMessageRunStatus.CANCELLED) {
        dispatch(slice.actions.setIsProcessing(false));
        dispatch(slice.actions.setStatus(status));
      } else if (status === InsightMessageRunStatus.ERROR) {
        dispatch(slice.actions.setIsProcessing(false));
        dispatch(slice.actions.setStatus(status));
        console.error("Error processing message");
      } else if (status === InsightMessageRunStatus.COMPLETED) {
        const responseMessage: InsightMessage = {
          id,
          msg,
          isRated,
          date: new Date().toISOString(),
          isAIMessage: true,
        };
        dispatch(slice.actions.loadResponse(responseMessage));
      } else {
        dispatch(slice.actions.setStatus(status));
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const getInsightMessageActions =
  (messageId: string): AppThunk<Promise<void>> =>
  async (dispatch) => {
    try {
      const response: AxiosResponse = await axios.get(
        `${API_PREFIX}/${messageId}/actions`,
      );
      const { actions } = response.data;
      dispatch(slice.actions.loadActions(actions));
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const processUserInput =
  (input: string, clusterId: string): AppThunk<Promise<void>> =>
  async (dispatch) => {
    try {
      dispatch(
        slice.actions.startedProcessingUserInput({
          input,
          runId: "",
          contentUsed: [],
          sources: [],
        }),
      );
      const response: AxiosResponse = await axios.post(
        `${API_PREFIX}/process_input`,
        { input, clusterId },
      );
      const { runId, contentUsed, sources } = response.data;
      dispatch(
        slice.actions.finishProcessingUserInput({
          input,
          runId,
          contentUsed,
          sources,
        }),
      );
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const cancelMessage =
  (messageId: string): AppThunk =>
  async (dispatch) => {
    try {
      await axios.put(`${API_PREFIX}/${messageId}/cancel`);
      dispatch(slice.actions.cancelProcessing(false));
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const { reducer } = slice;

export default slice;
