import { Helmet } from "react-helmet-async";
import ModuleLayout from "src/layouts/shared/layout/ModuleLayout";

const InsightLayout = () => (
  <>
    <Helmet title="Insight" />
    <ModuleLayout />
  </>
);

export default InsightLayout;
